<template>
  <div id="detail-scraper-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <div>
          <!-- title -->
          <b-card-title class="d-flex align-items-center">
            <i class="bi bi-info-circle"></i>
            <span style="margin-left: 5px">
              Detail Scraper <strong> {{ filter_data.scraper }} </strong>
            </span>
          </b-card-title>
        </div>
        <!-- filter component -->
        <div
          class="d-flex flex-wrap gap-5px justify-content-end align-items-center"
        >
          <!-- back button -->
          <b-button variant="outline-info" @click="goBack()">
            <feather-icon icon="ArrowLeftCircleIcon" /> Kembali
          </b-button>
          <!-- back button -->
          <b-button
            :variant="
              filter_data.is_exclude_completed ? 'success' : 'outline-success'
            "
            class="btn-icon"
            v-b-tooltip.hover.top="'Exclude Status Completed'"
            @click="
              (filter_data.is_exclude_completed = !filter_data.is_exclude_completed),
                getData()
            "
          >
            <i class="bi bi-check-circle"></i>
          </b-button>
          <!-- dataRangePicker component -->
          <dateRangePicker
            :from_date="filter_data.from_date"
            :to_date="filter_data.to_date"
            @update-value="dateFilterChanged"
          />
        </div>
      </b-card-header>
      <!-- loader component -->
      <loader v-if="is_loading" />
      <!-- table component -->
      <dataTableClientPagination
        v-else
        :data="table_data"
        :pagination="pagination"
      />
    </b-card>
  </div>
</template>
<script>
import useJwt from "@/auth/jwt/useJwt";
import dataTableClientPagination from "@/views/components/dataTableClientPagination.vue";
import dateRangePicker from "@/views/components/dateRangePicker.vue";
import loader from "@/views/components/loader.vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import moment from "moment";

export default {
  name: "ScraperDetail",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    dataTableClientPagination,
    loader,
    dateRangePicker,
  },
  data() {
    return {
      is_loading: true,
      filter_data: {
        from_date:
          this.$route?.query?.from_date ||
          moment(new Date())
            .subtract(7, "days")
            .format("YYYY-MM-DD 00:00:00"),
        to_date:
          this.$route?.query?.to_date ||
          moment(new Date()).format("YYYY-MM-DD 23:59:59"),
        scraper: this.$route.query.scraper,
        status: this.$route.query.status,
        is_exclude_completed: true,
      },
      pagination: {
        page: 1,
        items: 10,
        count: 0,
      },
      table_data: {
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "scraper_name",
            label: "nama scraper",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "status",
            label: "status",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "7%" },
          },
          {
            key: "error_message",
            label: "pesan error",
            thClass: "text-justify",
            tdClass: "text-justify",
          },
          {
            key: "username",
            label: "pic",
            thClass: "text-justify",
            tdClass: "text-justify",
          },
          {
            key: "solution",
            label: "solusi",
            thClass: "text-justify",
            tdClass: "text-justify",
          },
          {
            key: "start",
            label: "tanggal berjalan",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "end",
            label: "tanggal terhenti",
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "duration",
            label: "durasi",
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "count",
            label: "jumlah berita",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
          },
        ],
        items: [],
      },
      cancel_token: null,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    dateFilterChanged(data) {
      this.filter_data.from_date = data.from_date;
      this.filter_data.to_date = data.to_date;
      this.getData();
    },
    getData() {
      this.is_loading = true;
      if (this.cancel_token) {
        this.cancel_token.cancel("ERR_CANCELED");
      }
      this.cancel_token = axios.CancelToken.source();

      let params = {
        is_exclude_completed: this.filter_data.is_exclude_completed,
        from_date: this.filter_data.from_date,
        to_date: this.filter_data.to_date,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api =
        process.env.VUE_APP_API_URL +
        "scraper-log/detail/" +
        this.$route.query.scraper +
        "?" +
        query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancel_token.token })
        .then((res) => {
          this.cancel_token.value = null;
          this.table_data.items = (res.data.scraper_logs_data || []).sort(
            (a, b) => {
              return new Date(b.start) - new Date(a.start);
            }
          );
          this.pagination.count = this.table_data.items.length;
        })
        .catch((err) => {
          if (err.message != "ERR_CANCELED") {
            this.cancel_token.value = null;
          } else {
            this.cancel_token.value = "CANCELED";
          }
        })
        .finally(() => {
          if (!this.cancel_token.value) {
            this.is_loading = false;
          }
        });
    },
    goBack() {
      this.$router.back();
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>
